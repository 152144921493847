<template>
    <div class="_wrap">
        <div class="_auto">

            <div class="_bread">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

                    <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
                </el-breadcrumb>
            </div>


            <h4></h4>
            <div class="_cont">
                <div class="_p">为进一步加强我馆与观众的紧密联系，提升对公众的服务能力，欢迎您对我馆工作提出宝贵的建议和意见。</div>



                <el-form>
                    <el-form-item>
                        <el-input placeholder="请输入标题" v-model="form.title">
                            <template slot="prepend">标题</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input type="textarea" :autosize="{ minRows: 10 }" placeholder="请输入内容"
                            v-model="form.content">
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-input placeholder="请输入姓名" v-model="form.name">
                            <template slot="prepend">姓名</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入手机" v-model="form.tel">
                            <template slot="prepend">手机</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入邮箱" v-model="form.email">
                            <template slot="prepend">邮箱</template>
                        </el-input>
                    </el-form-item>
                </el-form>

                <div class="_submit">
                    <div class="_tij" @click="subMit">提交留言</div>
                    <div class="_cx" @click="reset">重写</div>
                </div>
            </div>
        </div>

        <fixIcon />
        <mainFooter ref="mainFooterRef"></mainFooter>

    </div>
</template>

<script>
import { postMsg } from '../../api/getData';
export default {
    data() {
        return {
            form: {
                content: "",
                email: "",
                name: "",
                tel: "",
            }
        }
    },
    methods: {
        subMit() {
            postMsg(this.form).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.reset()
                    return this.$message({
                        customClass: "myAlert",
                        type: "success",
                        message: res.message
                    })
                } else {
                    return this.$message({
                        customClass: "myAlert",
                        type: "warning",
                        message: res.message
                    })
                }
            })
        },
        reset() {
            this.form = {
                content: "",
                email: "",
                name: "",
                tel: "",
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
    width: 255px;
    height: 47px;
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

._wrap
{
    width: 100%;
    background-color: #F8F5EE;
    overflow: hidden;

    ._auto
    {
        width: 85%;
        margin: 0 auto;
        margin-top: 200px;


        h4
        {
            @include tit("../../assets/images/h4-25.png");
            margin: 0 auto;
            margin-bottom: 50px;
            width: 299px;
            height: 52px;

        }
    }
}

._cont
{

    height: 780px;
    background: #FFFFFF;
    padding: 65px;

    ._p
    {
        font-size: 18px;
        color: #333;
        margin-bottom: 40px;
        letter-spacing: 1px;
    }


    ._submit
    {
        display: flex;
        align-items: center;
        justify-content: center;

        >div
        {
            width: 200px;
            height: 48px;

            text-align: center;
            line-height: 48px;
            margin: 40px;
            cursor: pointer;
        }

        ._tij
        {
            color: white;
            background: #AF231C;

        }

        ._cx
        {
            color: #333;
            border: 1px solid #ccc;
        }
    }

}

::v-deep .el-input-group__prepend
{
    color: #333;

}


._wrap ._auto
{
    @include respondTo('phone')
    {
        margin-top: 80px;
        width: 95% !important;
        padding: 0;

        >h4
        {
            width: 173px;
            height: 30px;
            margin-top: 20px;
            margin-bottom: 30px;
            background-image: url("../../assets/images/phone/ly.png");
        }

        ._cont
        {
            padding: 5px;

            ._p
            {
                margin-bottom: 20px;
            }
        }
    }
}
</style>


<style>
@media (min-width:320px) and (max-width:480px) {
    .myAlert {
        top: 20% !important;
    }
}
</style>
